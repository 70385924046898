<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CAlert color="danger" closeButton :show.sync="showAlert">
                  {{ alertMsg }}
                </CAlert>
                <CForm>
                  <h1>تسجيل الدخول</h1>
                  <p class="text-muted">قم بملئ البيانات التاليه</p>
                  <CInput
                    placeholder="البريد الالكتروني"
                    autocomplete="email"
                    v-model="email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="كلمة المرور"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked" /></template
                    ><CSpinner color="info" />
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-right">
                      <CSpinner v-if="loading" color="info" />
                      <CButton
                        v-else
                        color="info"
                        class="px-4 btn-lg"
                        @click.prevent="login"
                        >دخول</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="info"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h1>لوحة تحكم الادارة</h1>
                <p>
                  لوحة التحكم الخاصة بالادارة . من فضلك قم بادخال البيانات بشكل
                  صحيح
                </p>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      email: null,
      password: null,
      showAlert: false,
      alertMsg: "من فضلك قم بكتابة البيانات بشكل صحيح",
    };
  },
  methods: {
    login() {
      if (
        this.email == "" ||
        this.password == "" ||
        this.email == null ||
        this.password == null
      ) {
        this.alertMsg = "من فضلك قم بكتابة البيانات بشكل صحيح";
        this.showAlert = true;
        return false;
      }

      this.loading = true;
      // Call API
      this.$http
        .post("/auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          // handle success
          if (res.data.status === 200) {
            const token = res.data.data.access_token.access_token;
            const adminData = res.data.data.admin;
            localStorage.setItem("admin-token", token);
            localStorage.setItem("admin-data", JSON.stringify(adminData));
            this.$store.commit("set", ["adminToken", token]);
            this.$store.commit("set", ["adminData", adminData]);
            this.$router.push({ name: "Home" });
            return true;
          }
          this.emailOrPasswordIncorrect();
          this.loading = true;
        })
        .catch((error) => {
          //
          this.emailOrPasswordIncorrect();
          this.loading = false;
        });
    },
    emailOrPasswordIncorrect() {
      this.alertMsg = "البريد الالكتروني او كلمة المرور غير صحيحه";
      this.showAlert = true;
    },
  },
};
</script>
